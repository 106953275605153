import {
  ApolloClient,
  ApolloLink,
  ServerError,
  HttpLink,
  HttpOptions,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { OktaAuth } from '@okta/okta-auth-js'
import { TFunction } from 'react-i18next'

import { TriggerErrorNotificationFunction } from '@firstbase/contexts/notifications'
import environmentVariables from '@firstbase/utils/environmentVariables'

import cache from './cache'
import {
  ADD_CUSTOMER_CATALOG_ITEM_EXCEPTION,
  SUPPRESS_DEFAULT_ERROR,
} from './constants'

const httpLink = (opts: HttpOptions | undefined) => new HttpLink(opts)

const getTerminatingLink = (uri: string) =>
  httpLink({
    uri: `${uri}/graphql`,
  })

const errorLink = (
  oktaAuth: OktaAuth,
  triggerErrorNotification?: TriggerErrorNotificationFunction,
  t?: TFunction
) =>
  onError(({ graphQLErrors, networkError, operation: { operationName } }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      // eslint-disable-next-line no-console
      console.log('networkError', networkError)
      oktaAuth.tokenManager.clear()
      oktaAuth?.signOut({ postLogoutRedirectUri: '/?session-expired' })
    }

    if (graphQLErrors) {
      // eslint-disable-next-line
      console.log('graphQLErrors', graphQLErrors)

      if (triggerErrorNotification) {
        if (!operationName.includes(SUPPRESS_DEFAULT_ERROR)) {
          const serverDataFetchingErrors = graphQLErrors.filter(
            ({ extensions }) => !!extensions?.errorCode
          )

          if (serverDataFetchingErrors.length && t) {
            serverDataFetchingErrors.forEach(
              ({ extensions, message, path }) => {
                const errorCode = { extensions }.extensions?.errorCode
                const exceptionType = { extensions }.extensions?.exceptionType
                let notificationMessage
                let notificationSubMessage
                /**
                 * Display server error message for whitelisted paths
                 * Does this for both the update org mutation and the offboard person one
                 * This is a hack is needed for Hybrid work M5 - SE-2362 and SE-5020 to show friendlier error messages
                 */
                const useMsgFromPaths = [
                  'updateOrganizationInventory',
                  'offboardPerson',
                ]
                /**
                 * Display server supplied error messages for whitelisted exceptionTypes
                 */
                const useMsgFromExceptionTypes: string[] = []
                const suppressNotificationForExceptionTypes: string[] = [
                  ADD_CUSTOMER_CATALOG_ITEM_EXCEPTION,
                ]

                if (
                  suppressNotificationForExceptionTypes.includes(exceptionType)
                )
                  return

                if (
                  exceptionType != null &&
                  useMsgFromExceptionTypes.includes(exceptionType)
                ) {
                  notificationMessage = message
                } else if (
                  path?.some((r) => useMsgFromPaths.includes(r.toString()))
                ) {
                  notificationMessage = message
                } else {
                  notificationMessage = t('Alerts.An error occurred')
                  notificationSubMessage = t(
                    'Alerts.If this error persists, contact us for support with reference code',
                    {
                      code: errorCode,
                    }
                  )
                }

                triggerErrorNotification(
                  notificationMessage,
                  notificationSubMessage
                )
              }
            )
          } else triggerErrorNotification()
        }
      }
    }
  })

const authLink = (oktaAuth: OktaAuth) =>
  setContext(async (_, { headers }) => {
    const additionalHeaders = {
      'X-Job-Title': `app-client:${window.location.pathname}`,
    }

    if (await oktaAuth.isAuthenticated()) {
      const token = oktaAuth.getAccessToken()
      return {
        headers: {
          ...headers,
          ...additionalHeaders,
          authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    }

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    }
  })

const client = (
  oktaAuth: OktaAuth,
  triggerErrorNotification?: TriggerErrorNotificationFunction,
  t?: TFunction
) =>
  new ApolloClient({
    link: ApolloLink.from([
      errorLink(oktaAuth, triggerErrorNotification, t),
      authLink(oktaAuth),
      getTerminatingLink(environmentVariables.get().VITE_API_URI),
    ]),
    connectToDevTools: environmentVariables.get().MODE === 'development',
    cache,
  })

export default client
