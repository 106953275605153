import { gql } from '@apollo/client'

import {
  PERSON_PROFILE_FULL,
  PERSON_REPLACEMENTS,
  SELF_PROFILE_FULL,
  PERSON_INVENTORIES,
} from './fragments'
import { RETRIEVAL_BASICS } from '../shared'

export const QUERY_SELF = gql`
  ${SELF_PROFILE_FULL}
  query getSelf {
    getSelf {
      ...SelfProfileFull
    }
  }
`

export const QUERY_MY_ORDERS = gql`
  ${PERSON_REPLACEMENTS}
  ${RETRIEVAL_BASICS}
  query getMyOrders {
    getSelf {
      id
      slug
      orders {
        id
        createdAt
        person {
          offboardingInfo {
            equipmentComment
          }
        }
        products {
          id
          etaStart
          etaEnd
          status
          trackingLink
          serialNumber
          returnReason
          returnReasonComment
          inventory {
            id
            description
            deployReason
          }
          sku {
            id
            category {
              skuType
            }
            vendor {
              name
              code
            }
            skuImages
            skuInformation {
              productTitle
            }
            metadata {
              id
              field
              value
            }
          }
          expeditedShipping {
            id
            availableForAll
            option
          }
          retrievalMethod {
            ...RetrievalBasics
          }
        }
        orderType
        orderStatus
        etaStart
        etaEnd
        productPackage {
          name
        }
      }
      replacements {
        ...PersonReplacements
      }
    }
  }
`

export const QUERY_MY_INVENTORY = gql`
  query getMyInventory {
    getSelf {
      id
      slug
      inventories {
        id
        serialNumber
        conditionStatus
        organization {
          name
        }
        deployStatus
        sku {
          vendor {
            name
            code
          }
          category {
            skuType
          }
          skuImages
          skuInformation {
            productTitle
          }
        }
        firstbaseSupplied
        gift
        description
        assetCostItem {
          id
          assetCost {
            id
            amount
          }
        }
      }
    }
  }
`

export const QUERY_PERSON = gql`
  ${PERSON_PROFILE_FULL}
  query getPerson($slug: String!) {
    getPerson(slug: $slug) {
      id
      slug
      platformAccess
      ...PersonProfileFull
    }
  }
`

export const GET_PERSON_INVENTORIES = gql`
  ${PERSON_INVENTORIES}
  query getPersonInventories($slug: String!) {
    getPerson(slug: $slug) {
      id
      inventories {
        ...PersonInventories
      }
    }
  }
`

export const QUERY_SELF_PACKAGES_ORG_AVAILABLE_SKUS = gql`
  query getSelfPackagesWithAvailableSku($countryCode: String) {
    getSelf {
      id
      packages {
        id
        skus(filter: { countryCode: $countryCode }) {
          id
        }
      }
    }
  }
`

export const QUERY_VERIFY_PERSON_REACTIVATION = gql`
  query verifyPersonReactivation($personIds: [ID]!) {
    verifyPersonReactivation(personIds: $personIds) {
      personId
      valid
      reason
      returnOrderStatus
      returnOrderId
    }
  }
`

export const GET_PEOPLE_FILTERS = gql`
  query getPeopleFilters {
    getOrganizationPackages(
      pagingAndSorting: {
        pageNumber: 1
        pageSize: 99999
        sort: { field: "name", direction: ASC }
      }
      filter: { active: true }
    ) {
      data {
        id
        name
      }
    }
    getOrganization {
      accesses(pagingAndSorting: { pageNumber: 1, pageSize: 5 }) {
        data {
          displayName
          groupNames
        }
      }
    }
  }
`

export const GET_ALL_ACCESSES = gql`
  query getAllAccesses {
    getOrganization {
      accesses(
        pagingAndSorting: { pageNumber: 1, pageSize: 5 }
        filter: { includeAllAccessLevels: true }
      ) {
        data {
          displayName
          groupNames
        }
      }
    }
  }
`
