import React from 'react'
import { Link } from 'react-router-dom'
import classNamesBind from 'classnames/bind'

import Loading from '@firstbase/components/Atoms/Loader'

import css from './Button.module.css'

const classNames = classNamesBind.bind(css)

export type ButtonHTMLAttributes = {
  'data-testid'?: string
  'data-appcues-id'?: string
  id?: string
}

export type ButtonKinds =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'error'
  | 'outline'

export interface OwnProps {
  kind?: ButtonKinds
  label: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  className?: string
  onClick?: (e?: Event) => void
  to?: string
  href?: string
  target?: string
  flexFill?: boolean
  flexOnMobile?: boolean
  loading?: boolean
  noMargin?: boolean
  small?: boolean
  attr?: ButtonHTMLAttributes
  tabIndex?: number
  icon?: React.ReactNode
  iconOrder?: 'before' | 'after'
  iconClassName?: string
  borderType?: 'round' | 'square'
  active?: boolean
}

const Button: React.FC<OwnProps> = ({
  className,
  target,
  label,
  kind = 'primary',
  type = 'button',
  disabled = false,
  onClick,
  to,
  href,
  flexFill,
  flexOnMobile,
  loading,
  noMargin,
  small,
  attr,
  icon,
  iconOrder = 'after',
  iconClassName,
  borderType = 'round',
  active,
}) => {
  const handleClick = () => {
    if (onClick) onClick()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.keyCode === 32 && !disabled && onClick) {
      event.preventDefault()
      onClick()
    }
  }

  const renderButtonIcon = (visible: boolean) =>
    icon && visible ? (
      <div className={classNames('icon', iconClassName, iconOrder)}>{icon}</div>
    ) : null

  const renderContent = () => (
    <>
      {renderButtonIcon(iconOrder === 'before')}
      <div className={classNames('text', { hide: loading })}>{label}</div>
      {renderButtonIcon(iconOrder === 'after')}
      <div
        className={classNames('loader', {
          link: kind === 'link',
          hide: !loading,
        })}
      >
        <Loading
          color={['tertiary', 'link'].includes(kind) ? 'primary' : 'white'}
          size={1}
        />
      </div>
    </>
  )

  const baseClassName = kind === 'link' ? 'link' : 'button'

  if (to || href) {
    if (to) {
      return (
        <Link
          target={target}
          className={classNames(
            baseClassName,
            kind,
            { disabled, flexFill, noMargin, flexOnMobile },
            className,
            borderType
          )}
          to={to}
          {...attr}
        >
          {renderContent()}
        </Link>
      )
    }

    return (
      <a
        target={target}
        className={classNames(
          baseClassName,
          kind,
          { disabled, flexFill, noMargin, flexOnMobile },
          className,
          borderType
        )}
        href={href}
        {...attr}
      >
        {renderContent()}
      </a>
    )
  }

  return (
    <button
      type={type}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      disabled={disabled}
      className={classNames(
        baseClassName,
        kind,
        { disabled, flexFill, noMargin, flexOnMobile, small },
        className,
        borderType,
        { active: active && kind === 'outline' }
      )}
      {...attr}
    >
      {renderContent()}
    </button>
  )
}

export default Button
