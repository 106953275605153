import React from 'react'
import classNamesBind from 'classnames/bind'

import css from './Heading.module.css'

const classNames = classNamesBind.bind(css)

interface OwnProps {
  kind?: 'h1' | 'h2' | 'h3' | 'h4' | 'p'
  title: React.ReactNode
  className?: string
  bold?: boolean
}

const Heading: React.FC<OwnProps> = ({
  kind = 'h1',
  title,
  className,
  bold,
  ...rest
}) => {
  const Kind = kind

  return (
    <Kind className={classNames('heading', className, { bold })} {...rest}>
      {title}
    </Kind>
  )
}

export default Heading
