const FLAGS = {
  SE3225_WELCOME_MEDIA: 'se-3225-welcome-media',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-3157
  SE3096_IT_CLOSET: 'se-3096-it-closet',

  // Remove flag -
  SE3106_DELIVERY_STATUS: 'se-3106-add-delivery-statistics',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2802
  SE2220_SUPPORTED_COUNTRIES: 'SE-2220-supported-countries',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2713
  SE555_US_VERIFICATION: 'SE-555-united-states-verification',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2247
  SE2170_ADMIN_DASHBOARD: 'se-2170-admin-dashboard',

  // Remove flag - https://firstbasehq.atlassian.net/browse/SE-2246
  SE2154_PRODUCT_PORTAL_LINK: 'se-2154-show-product-portal-link-app-cues',

  // flag removed when one off orders is complete
  SE1317_ONE_OFF_ORDERS: 'se-1317-one-off-orders',

  // flag removed when developer controls available for all users (liaise with RVB)
  PUBLIC_API_BETA_USER: 'public-api-beta-user',

  // remove once help center has been launched (https://firstbasehq.atlassian.net/browse/SE-1560)
  SE1525_HELP_CENTER_LINK: 'se-1525-help-center-link',

  // remove flag once validation testing has occurred in all environments
  SE1241_APPCUES: 'se-1241-appcues',

  // remove flag once returns are tested and available to everyone (https://firstbasehq.atlassian.net/browse/SE-2018)
  SE2018_EXPEDITED_SHIPPING: 'se-2018-expedited-shipping',

  // remove flag once replacements tab is tested and available to everyone
  SE2076_replacements_tab: 'se-2076-replacements-tab',

  // remove flag once replacements are viewable by all workers
  SE2077_WORKER_VIEW_REPLACEMENTS: 'se-2077-worker-view-of-replacements',

  // remove flag once tested on production and we want displayed to all
  SE2189_SAVE_SHIPPING_ADDRESS_CHECKBOX:
    'se-2189-save-shipping-address-checkbox',

  // remove flag once validation and testing has occurred in all environments
  SE2322_RESEND_ACTIVATION_EMAIL: 'se-2322-resend-activation-email',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2429)
  SE2429_EXPEDITED_SHIPPING_ONE_OFF_ORDER:
    'se-2429-expedited-shipping-one-off-order',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2425)
  SE2425_EXPEDITED_SHIPPING_AUTOMATIC_ORDER:
    'se-2425-expedited-shipping-automatic-order',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2522)
  SE2522_INTEGRATIONS_PAGE: 'se-2522-integrations-page',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2339)
  SE_2339_API_TOKEN_PAGE_UPDATES: 'se-2339-api-token-page-updates',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2685)
  SE2685_INTERNATIONAL_ADDRESS_VALIDATION:
    'se-2685-international-address-validation',

  // remove flag once holiday loader no longer needed (https://firstbasehq.atlassian.net/browse/SE-3024)
  SE_3019_HOLIDAY_LOADER: 'SE-3019-holiday-loader',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-3020)
  SE_3020_REPLACEMENTS_MODIFY_SHIPPING: 'se-3020-replacements-modify-shipping',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-3170)
  SE_3170_ADD_CONDITION_INTO_CUSTOMER_SHIPMENT_NOTICES:
    'se-3170-add-condition-into-customer-shipment-notices',

  // remove flag once feature is available to all users
  SE_3180_BUYOUTS: 'se-3180-buyouts-fe',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2574)
  SE_2574_SETTINGS_SHIPPING_PAGE: 'se-2574-settings-shipping-page',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-3575)
  SE_3575_USED_EQUIPMENT_ONE_OFF_ORDERS_CUSTOMER_SUPPLIED:
    'se-3575-used-equipment-one-off-orders-customer-supplied',

  // Remove flag when feature is available to all users
  SE_3551_AUTOMATIONS_TAB_ADMIN_APP: 'se-3551-automations-tab-in-admin-app',

  // Remove flag when feature is available to all users
  SE_3551_AUTOMATIONS_EMAIL_SWAPPING_ONBOARDING_SETTING:
    'se-3551-automations-email-swapping-onboarding-setting',

  // Remove flag when feature is available to all users
  SE_3551_AUTOMATIONS_EMAIL_SWAPPING_OFFBOARDING_SETTING:
    'se-3551-automations-email-swapping-offboarding-setting',

  // remove flag once feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-3575)
  SE_3731_USED_EQUIPMENT_REPLACEMENTS_CUSTOMER_SUPPLIED:
    'se-3731-used-equipment-replacements-customer-supplied',

  // Remove flag when feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-2741)
  SE_2741_USED_EQUIPMENT_CONDITION_IN_ORDER_TABLE:
    'se-2741-used-equipment-condition-in-order-table',

  // Remove flag when feature is available to all users (https://firstbasehq.atlassian.net/browse/SE-3837)
  SE_3837_RETRIEVAL_TRACKER: 'se-3837-retrieval-tracker',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4298)
  SE_4298_HOME_PAGE_OPTIMIZATIONS: 'se-4298-home-page-optimizations',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4288)
  SE_4288_LEGACY_EQUIPMENT_FORM_UPDATE: 'se-4288-legacy-equipment-form-update',

  // Remove flag when feature is tested (https://firstbasehq.atlassian.net/browse/SE-4312)
  SE_4312_REASSIGNING_EQUIPMENT_FLOW: 'se-4312-reassigning-equipment-flow',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4311)
  SE_4311_DROP_OFF_AT_OFFICE: 'se-4311-drop-off-at-office',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4289)
  SE_4289_RETURN_TO_OFFICE_OFFBOARDING: 'se-4289-return-to-office-offboarding',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4225)
  SE_4073_IT_CLOSET_ENABLED_FLAG_TOGGLING_SUPPORT:
    'se-4073-it-closet-enabled-flag-toggling-support',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4539)
  SE_4539_REASSIGN_EQUIPMENT_THROUGH_INVENTORY_MANAGEMENT:
    'se-4539-reassign-equipment-through-inventory-management',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4813)
  SE_4813_ADMIN_ALL_INVENTORY_FILTER_BY_OFFICE:
    'se-4813-admin-all-inventory-filter-by-office',

  // Remove flag when okta recipe is updated with employment types for all clients in Workato (https://firstbasehq.atlassian.net/browse/SE-4670)
  SE_4670_INTEGRATIONS_OKTA_ONBOARDING_EMPLOYMENT_TYPES:
    'se-4670-integrations-okta-onboarding-employment-types',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-3971)
  SE_3971_CREATE_PACKAGELESS_PEOPLE: 'SE-3971-create-packageless-people',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-5023)
  SE_5023_OFFBOARDING_UPDATE_EQUIPMENT_RETURN_OPTIONS:
    'se-5023-offboarding-update-equipment-return-options',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4285)
  SE_4285_ADD_DEPLOY_REASON_FILTER: 'se-4285-add-deploy-reason-filter',

  // Remove flag when we decide what to do with this popup
  SE_5111_NEW_VERSION_ERROR_POPUP: 'se-5111-new-version-error-popup',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-4562)
  SE_4562_USAGE_REPORT_AND_FEES_MVP: 'se-4562-usage-report-and-fees-mvp',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-5165)
  SE_5165_PACKAGE_FILTERS_AND_GEO_CONSTRAINTS_M1:
    'se-5165-package-filters-and-geo-constraints-m-1',

  // Remove flag when feature has been implemented in retool
  SE_6099_SHOW_EXPECTED_BY_UI: 'se-6099-show-expected-by-ui',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-5956)
  SE_5956_FIXING_INVENTORY_ORDERS: 'se-5956-fixing-inventory-orders',

  // Don't remove until all clients can ship to UK warehouses (https://firstbasehq.atlassian.net/browse/SE-6197)
  SE_6197_SHOW_UK_WAREHOUSE_OPTIONS_FOR_SHIPMENT_NOTICES:
    'se-6197-show-uk-warehouse-options-for-shipment-notices',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-5970)
  SE_5970_GEO_ORDER_GUARDRAILS: 'se-5970-geo-order-guardrails',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-6457)
  SE_6457_GROUPED_BY_PRODUCTS_EXPORTS_UPDATE:
    'se-6457-grouped-by-products-exports-update',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/admin-self-service-create-assets-on-platform-4bddb740be7d)
  PE_7_ADMIN_SELF_SERVICE_M1a: 'pe-7-admin-self-service-m-1a',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-6458)
  SE_6458_ASSET_MANAGEMENT_EXPORT_MODAL:
    'se-6458-asset-management-export-modal',

  // Remove flag when feature is being used by all clients (https://firstbasehq.atlassian.net/browse/SE-6459)
  SE_6459_ASSET_MANAGEMENT_EXPORT_HISTORY:
    'se-6459-asset-management-export-history',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/issue/PE-92/remove-ff-pe-7-admin-self-service-m-1b)
  PE_7_ADMIN_SELF_SERVICE_M_1B: 'PE-7-admin-self-service-m-1b',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/admin-self-service-standard-catalog-7b868ae9b038/PE)
  SE_4591_STANDARD_CATALOG_M2: 'se-4591-standard-catalog-m-2',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/admin-self-service-standard-catalog-7b868ae9b038/PE)
  PE_343_CUSTOMER_CATALOG_REMOVE_ITEMS: 'PE-343-customer-catalog-remove-items',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/project/shipment-notice-enhancements-0d30226c3fb9)
  PE_329_SHIPMENT_NOTICE_ENHANCEMENTS_M_1:
    'pe-329-shipment-notice-enhancements-m-1',

  // Remove flag when feature is being used by all clients (https://linear.app/firstbase/issue/PE-266/fe-or-returns-and-replacements-or-provide-additional-comment-field-for)
  PE_266_RETURNS_ADDITIONAL_COMMENT: 'pe-266-returns-additional-comment',

  // Remove flag when used by all
  PE_413_EXPORT_UPGRADE_FORM: 'pe-413-export-upgrade-form',

  // Remove flag when used by all (https://linear.app/firstbase/issue/PE-156/it-closet-or-geo-guardrails-or-add-regionsupplied-by-filter)
  PE_156_IT_CLOSET_GEO_GUARDRAILS: 'pe-156-it-closet-geo-guardrails',

  // Remove flag when used by all
  PE_739_SMARTY_ADDRESS_VALIDATION_IMPROVEMENT:
    'pe-739-smarty-address-validation-improvement',

  // Remove flag when used by all
  PE_460_UPDATE_RETURN_ORDER_EXPORT: 'pe-460-update-return-order-export',

  // Remove flag when used by all
  PE_792_SKU_METADATA_M_2: 'pe-792-sku-metadata-m-2',

  // Remove flag when used by all
  PE_846_ARCHIVE_ASSETS: 'pe-846-archive-assets',

  // Remove flag when used by all
  PE_501_EXPORT_PACKAGES_AS_CSV: 'pe-501-export-packages-as-csv',

  // No removal until further notice
  PE_965_FIRSTBASE_ASSISTANT: 'pe-965-firstbase-assistant',

  // Remove flag when used by all
  PE_116_LEAVER_RETURN_KIT_TRACKER: 'pe-116-leaver-return-kit-tracker',

  PE_805_ORDERS_UPDATED_AT: 'pe-805-orders-updated-at',

  PE_982_MERGE_INTEGRATIONS: 'pe-982-merge-integrations',

  // Remove after 6 weeks have passed since it was turned on
  PE_1113_ONE_OFF_RETURNS_OFFBOARDING_QUESTION:
    'pe-1113-one-off-returns-offboarding-question',

  // Remove after 6 weeks have passed since it was turned on
  PE_1097_SHIPMENT_NOTICES_MILESTONE_4: 'pe-1097-shipment-notices-milestone-4',

  // Remove after 6 weeks have passed since it was turned on
  PE_1141_SHIPMENT_NOTICES_MILESTONE_5: 'pe-1141-shipment-notices-milestone-5',

  // Remove flag when integrations are fully migrated
  PE_1409_HIDE_OLD_INTEGRATIONS: 'pe-1409-hide-old-integrations',
} as const

export default FLAGS
