import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import client from '@firstbase/config/apollo'

import {
  CUST_ADMIN,
  CUST_ADMIN_READ_ONLY,
} from '@firstbase/constants/permissions'

import { ApolloError } from '@apollo/client'
import { CREATE_PERSON_FOR_JIT_USER } from '@firstbase/data/people'
import { useNotifications } from '@firstbase/contexts/notifications'
import { LoadingOverlay } from '@firstbase/components/Atoms/Loader'
import useCheckoutBuySuccess from '@firstbase/hooks/useCheckoutBuySuccess'
import { Tokens } from '@okta/okta-auth-js'
import OktaSignInWidget from './OktaSignInWidget'

import { SignInFormI } from './types'

type OwnProps = {
  config: SignInFormI
}

const SignInForm = ({ config }: OwnProps) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [checkSession, setCheckSession] = useState(true)
  const { setNotification } = useNotifications()
  const { isComingFromBuyout, search } = useCheckoutBuySuccess()

  // sessionChecker checks to see if we have a cookie from idp validated login
  const sessionChecker = async () => {
    const session = await oktaAuth.session.exists()
    if (session) {
      const tokens = await oktaAuth.token.getWithoutPrompt()
      oktaAuth.handleLoginRedirect(tokens.tokens)
    }
    setCheckSession(false)
  }

  // jitUser creates the person in our database if a JIT user signed-in
  const jitUser = async () => {
    try {
      await client(oktaAuth).mutate({
        mutation: CREATE_PERSON_FOR_JIT_USER,
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      setNotification({
        message: (error as ApolloError).message || undefined,
        variant: 'error',
      })
    }
    await Promise.allSettled([
      oktaAuth.tokenManager.renew('idToken'),
      oktaAuth.tokenManager.renew('accessToken'),
    ])
  }

  const onSuccess = (tokens: Tokens) => {
    oktaAuth.handleLoginRedirect(tokens)
  }

  const onError = (err: any) => {
    //  eslint-disable-next-line no-console
    console.error('error logging in', err)
  }

  if (!authState) return null

  if (authState.isAuthenticated) {
    // check if user was JIT created
    const personId = authState.accessToken?.claims?.personId
    if (!personId || String(personId).trim().length < 1) {
      jitUser()
      return <LoadingOverlay />
    }
    const permissions = authState.accessToken?.claims.groups
    const isAdmin =
      permissions.includes(CUST_ADMIN) ||
      permissions.includes(CUST_ADMIN_READ_ONLY)

    // HACK: For when coming from stripe redirect, add search parameters for component
    return (
      <Redirect
        to={
          isAdmin
            ? '/home'
            : { pathname: '/orders', search: isComingFromBuyout ? search : '' }
        }
      />
    )
  }

  if (checkSession) {
    sessionChecker()
    return <LoadingOverlay />
  }

  return (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  )
}
export default SignInForm
