import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

// TODO: Upgrade to version 6.3.0 or later for Typescript types
import OktaSignIn from '@okta/okta-signin-widget'

import { Tokens } from '@okta/okta-auth-js/lib/types/Token'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

import './signinwidget.css'
import Error from '@firstbase/components/Atoms/Error'
import { useTranslation } from 'react-i18next'
import InlineAlert, {
  Kinds,
} from '@firstbase/components/Atoms/InlineAlert/InlineAlert'
import { SignInFormI } from './types'

type OwnProps = {
  onSuccess: (tokens: Tokens) => void
  onError: (error: Error) => void
  config: SignInFormI
}

const OktaSignInWidget = ({ config, onSuccess, onError }: OwnProps) => {
  const newConfig = useMemo(() => ({ ...config }), [config])
  const widgetRef = useRef<HTMLDivElement>(null)
  const { search } = useLocation()
  const [isExpired, setIsExpired] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!widgetRef.current) return () => null

    const params = new URLSearchParams(search)
    const token = params.get('token')
    const recoveryToken = params.get('recoveryToken')

    if (params.has('session-expired')) {
      setIsExpired(true)
    }

    if (token) {
      newConfig.stateToken = token
    }

    // Handles password recovery
    if (recoveryToken) {
      newConfig.recoveryToken = recoveryToken
    }

    const widget = new OktaSignIn(newConfig)

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError)

    return () => widget.remove()
  }, [config, onSuccess, onError, search, newConfig])

  return (
    <>
      {isExpired ? (
        <InlineAlert
          kind={Kinds.WARNING}
          text={t(
            'Alerts.Sorry your session has expired. For security reasons, please log in again.'
          )}
        />
      ) : (
        <></>
      )}
      <div ref={widgetRef} />
    </>
  )
}

export default OktaSignInWidget
