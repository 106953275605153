import React from 'react'
import classNamesBind from 'classnames/bind'
import css from './Label.module.css'

const classNames = classNamesBind.bind(css)

type Classes = {
  label?: string
  subLabel?: string
}

export interface OwnProps extends React.HTMLProps<HTMLLabelElement> {
  subLabel?: React.ReactNode
  noMargin?: boolean
  lightText?: boolean
  for?: string
  className?: string
  classes?: Classes
  label: string
}

const Label = ({
  label,
  for: htmlFor,
  subLabel,
  noMargin,
  lightText,
  className,
  classes,
  children,
  ...rest
}: OwnProps) => {
  const renderSubLabel = () => {
    if (!subLabel) return null

    return (
      <div className={classNames('subLabel', classes?.subLabel)}>
        {subLabel}
      </div>
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      data-testid={`${label || 'label'}-testid`}
      className={
        className ||
        classNames('container', { noMargin, lightText }, classes?.label)
      }
      id={htmlFor}
      {...rest}
    >
      {label || children}
      {!children ? renderSubLabel() : null}
    </label>
  )
}

export default Label
